export default {
    id: 0,
    new: true,
    content: [],
    media: [],
    type: "",
    featuredImage: null,
    snackbar: null,
    categories: [],
    selectedCategory: null,
    post_published: false,
}
