import { render, staticRenderFns } from "./NewPost.vue?vue&type=template&id=68a2db22&scoped=true&"
import script from "./NewPost.vue?vue&type=script&lang=js&"
export * from "./NewPost.vue?vue&type=script&lang=js&"
import style0 from "./NewPost.vue?vue&type=style&index=0&id=68a2db22&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68a2db22",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VCol,VRow,VSelect,VSnackbar,VTab,VTabItem,VTabs,VTabsItems,VTextField})
