export default {
    getPosts (context, {page, type}){
        let page_number = page.page || context.state.pagination.page;
        let rowsPerPage = page.itemsPerPage || context.state.pagination.rowsPerPage;
        window.axios.get(`posts?type=${type}&lang=nl&page=${page_number}&rowsPerPage=${rowsPerPage}`)
            .then((response) => {
                context.commit('updatePosts', response.data);
            });
    },
}
