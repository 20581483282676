import router from '@/router';
export default {
    initialize({ commit, dispatch, rootGetters }, type) {
        commit('clear');
        commit('setType', type);
        dispatch('getCategories');
        let languages = rootGetters['config/languages'];
        for (let language of languages)
            commit('addEditor', {
                language,
                title: '',
                subtitle: '',
                content: ''
            });
    },
    setEditorContent({commit}, {idx, content}) {
        commit('setContent', {idx, content});
    },
    setEditorTitle({commit}, {idx, title}) {
        commit('setTitle', {idx, title});
    },
    setEditorSubtitle({commit}, {idx, subtitle}) {
        commit('setSubtitle', {idx, subtitle});
    },
    setFeaturedImage({commit}, {value}) {
        commit('setFeaturedImage', {value});
    },
    syncMedia({commit}, media) {
        commit('syncMedia', media);
    },
    showSnackbar({commit}, snackbar) {
        commit('showSnackbar', snackbar);
    },
    hideSnackbar({commit}) {
        commit('showSnackbar', null);
    },
    setSelectedCategory({commit}, category) {
        commit('setSelectedCategory', category);
    },
    setPublished({commit}, published){
        commit('setPublished', published)
    },
    async getCategories({commit, getters}) {
        let resp = await window.axios.get('posts/categories', {
            params: {
                type: getters.type
            }
        });
        commit('setCategories', resp.data.data);
    },
    async save({getters, commit}, published = true) {
        commit('setPublished', published);
        try {
            if (getters.new) {
                let resp = await window.axios.post('posts', getters.saveData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                commit('setId', {id: resp.data.id});
                await router.replace({name: 'EditPost', params: {id: resp.data.id}});
            } else {
                let resp = await window.axios.post(`posts/${getters.id}`, getters.saveData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                let data = resp.data.data;
                commit('setFeaturedImage', {value: data.featuredImage});
                commit('syncMedia', data.media);
            }
            commit('showSnackbar', {message: "Je bericht is opgeslagen", timeout: 5000, color: 'success'});
        } catch {
            commit('showSnackbar', {message: "Kritikale fout tijdens het opslaan", timeout: 5000, color: 'error'});
        }
    },
    async load({commit, getters}, {id}) {// eslint-disable-line no-unused-vars
        let resp = await window.axios.get(`posts/${id}`);
        let data = resp.data.data;
        for(let content of data.content) {
            let idx = getters.editors.findIndex(x => x.language.code === content.code)
            commit('setContent', {idx, content: content.content});
            commit('setTitle', {idx, title: content.title});
            commit('setSubtitle', {idx, subtitle: content.subtitle});
        }
        commit('setId', {id: data.id});
        commit('setType', {type: data.type});
        commit('setFeaturedImage', {value: data.featuredImage});
        commit('syncMedia', data.media);
        commit('setSelectedCategory', data.post_category_id);
        commit("setPublished", data.post_published);
    }
}
