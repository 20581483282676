<template>
    <div>
        <v-app-bar app dense clipped-left>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>

            <v-toolbar-title>PowerCMS</v-toolbar-title>
            <v-spacer></v-spacer>
            <slot name="actions"></slot>
        </v-app-bar>

            <v-navigation-drawer
                permanent
                app
                clipped
            >
                <template v-slot:prepend>
                    <v-list-item two-line>
                        <v-list-item-avatar>
                            <img src="https://randomuser.me/api/portraits/women/81.jpg">
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{ user.name }}</v-list-item-title>
                            <v-list-item-subtitle class="green--text font-weight-bold">Logged In</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>

                <v-divider></v-divider>

                <v-list dense>
                    <v-list-item
                        v-for="item in nav"
                        :key="item.text"
                        link
                        :to="item.to"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link @click="logout">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Uitloggen</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>

            <v-main>
                <v-container>
                    <slot></slot>
                </v-container>
            </v-main>
    </div>


</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {pluralize} from "../utils/pluralizer";
import {ucfirst} from "../utils/stringHelpers";

export default {
    name: "DashboardLayout.vue",
    computed: {
        ...mapGetters({
            user: 'auth/user',
            getConfig: 'config/getConfig'
        }),
        nav() {
            const nav = [];
            nav.push({icon: 'mdi-account-multiple', text: 'Gebruikers', to: {name: 'Users'}});
            if (this.getConfig.post_types) {
                for (const postType of this.getConfig.post_types)
                    nav.push({
                        icon: 'mdi-post',
                        text: ucfirst(pluralize(postType)),
                        to: {name: 'Posts', params: {type: postType}}
                    });
            }
            nav.push({icon: 'mdi-cog', text: 'Systeeminstellingen', to: {name: 'Settings'}});
            nav.push({icon: 'mdi-form-select', text: 'Contact form', to: {name: 'Forms', params: {type: "forms"}}});
            return nav;
        }
    },
    methods: {
        ...mapActions({
            logout: 'auth/logout',
            config: 'config/getConfig'
        }),
    },
    /*data() {
        return {
            nav: [
                {icon: 'mdi-account-multiple', text: 'Gebruikers', to: {name: 'Users'}},
                {icon: 'mdi-post', text: 'Blogs', to: {name: 'Posts', params: { type: "blog"}}},
                {icon: 'mdi-post', text: 'Arrangementen', to: {name: 'Posts', params: { type: "arrangement"}}},
                {icon: 'mdi-cog', text: 'Systeeminstellingen', to: {name: 'Settings'}},
                {icon: 'mdi-form-select', text: 'Contact form', to: {name: 'Forms', params: {type: "forms"}}},

            ]
        }
    }*/
}
</script>

<style scoped>

</style>
